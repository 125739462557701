<template>
    <el-container>
        <el-header>
            <Header />
        </el-header>
        <el-container>
            <el-aside width="240px" style="height: 100%;text-align: left;padding-left: 13px;background-color:#545c64">
                <el-menu :default-active="listIndex" 
                background-color="#545c64"
                text-color="#fff"
                @select="handleSelect" router>
                    <el-menu-item-group>
                        <el-menu-item index="/system/companyMaintain">{{ $t("system.companyMaintain") }}</el-menu-item>
                        <el-menu-item index="/system/brandMaintain">{{ $t("system.brandMaintain") }}</el-menu-item>
                        <el-menu-item index="/system/userMaintain">{{ $t("system.userMaintain") }}</el-menu-item>
                        <el-menu-item index="/system/dataMaintain">{{ $t("system.dataMaintain") }}</el-menu-item>
                        <el-menu-item index="/system/fieldMaintain">{{ $t("system.fieldMaintain") }}</el-menu-item>
                        <el-menu-item index="/system/layerMaintain">{{ $t("system.layerMaintain") }}</el-menu-item>
                        <el-menu-item index="/system/cityMaintain">{{ $t("system.cityMaintain") }}</el-menu-item>
                        <el-menu-item index="/system/compititerMaintain">{{ $t("system.compititerMaintain") }}</el-menu-item>
                        <el-menu-item index="/system/reportStatistics">{{ $t("system.reportStatistics") }}</el-menu-item>
                        <el-menu-item index="/system/logStatistics">{{ $t("system.logStatistics") }}</el-menu-item>
                        <!-- <el-menu-item index="/system/otherApplication">{{ $t("system.otherApplication") }}</el-menu-item> -->
                    </el-menu-item-group>
                </el-menu>
            </el-aside>
            <el-main style="padding: 0px;">
                <router-view />
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import Header from "@/components/HeaderMenu.vue";
export default {
    components: {
        Header
    },
    data(){
        return {
            listIndex: "",
        }
    },
    mounted(){
        //左侧导航栏落焦位置确认
        if(this.$route.path != "/system") {
            this.listIndex = this.$route.path;
        } else {
            this.listIndex = "/system/companyMaintain";
        }
    },
    methods: {
        handleSelect(command){
        }
    }
}
</script>

<style>

</style>